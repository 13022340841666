import React, { Component } from 'react';

class Hobbies extends Component {
  render() {

    if(this.props.data){
      var hobbies = this.props.data.map(function(hobby){
          var hobbyIcon = 'images/icons/'+hobby.icon;
        return <li key={hobby.name}>
              <div className="hobby-icon">
                  <img alt={hobby.attribute} src={hobbyIcon}/>
              </div>
              <div className="hobby-text">
                  <h4>{hobby.name}</h4>
                  <em>Icon Credit: {hobby.attribute}</em>
              </div>
          </li>

      })
    }

    return (
      <section id="hobby">
      <div className="text-container">
         <div className="row">

            <div className="two columns header-col">
               <h1><span>Hobbies</span></h1>
            </div>
             <div className="row">
                 <div className="nine columns main-col">
                     <div className="row item">
                         <ul>

                         {hobbies}
                         </ul>
                     </div>
                 </div>
             </div>

            </div>
         </div>
   </section>
    );
  }
}

export default Hobbies;
