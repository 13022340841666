import React, { Component } from 'react';

class Experience extends Component {
  render() {

    if(this.props.data){
      var experiences = this.props.data.map(function(experience){
        return  <div key={experience.name}>
            <h3>{experience.name} </h3>
            <p className="info">{experience.date} <span>&bull;</span>
                <em className="received"> {experience.description}</em>
            </p>

        </div>
      })
    }

    return (
      <section id="experience">
      <div className="text-container">
         <div className="row">

            <div className="two columns header-col">
               <h1><span>Experience</span></h1>
            </div>
             <div className="row">
                 <div className="nine columns main-col" id="cert-list">
                     <div className="row item">

                         {experiences}

                     </div>
                 </div>
             </div>

            </div>
         </div>
   </section>
    );
  }
}

export default Experience;
