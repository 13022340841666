import React, {Component} from 'react';

class Certifications extends Component {
    render() {

        if (this.props.data) {
            var certifications = this.props.data.map(function (certification) {
                return <div key={certification.name}>
                    <h3>{certification.name} - <em className="received">{certification.description}</em></h3>
                    <p className="info">Received : {certification.received} <span>&bull;</span>
                        <em className="received"> Expire : {certification.expire}</em>
                    </p>

                </div>
            })
        }

        return (
            <section id="certification">
                <div className="row text-container">
                    <div className="three columns header-col">
                        <h1><span>Certifications</span></h1>
                    </div>

                    <div className="row">
                        <div className="nine columns main-col" id="cert-list">
                            <div className="row item">

                                    {certifications}

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Certifications;
